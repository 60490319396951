import { extractDomainFromEmail, getLocaleFromUrl } from 'utils';
import { debounce } from '../util/debounce';
import {
  AccordionEvent,
  AccordionEventProperties,
  DataLayerEvent,
  DataLayerInitializedProperties,
  FilterEventProps,
  ProductClickEvent,
  ProductEventProperties,
  PromoClickEvent,
  PromoClickEventProperties,
  PromoViewEvent,
  PromoViewEventProperties,
  SearchClickEventProps,
  SearchEventCommonProps,
} from './analyticsEventTypes';

declare global {
  // eslint-disable-next-line prefer-type-alias/prefer-type-alias
  interface Window {
    dataLayer?: Object[];
  }
}
function pushDataLayerEvent(event: DataLayerEvent) {
  (window.dataLayer || []).push(event);
}

function initialize(
  userId: undefined | string,
  email: undefined | string,
  currency: string
) {
  const eventProperties: DataLayerInitializedProperties = {
    event: 'dataLayer-initialized',
    user: {
      user_unity_id: userId,
      user_logged_in: userId ? 'yes' : 'no',
      user_email_domain: email ? extractDomainFromEmail(email) : undefined,
    },
    environment: {
      environment_locale: getLocaleFromUrl(),
      environment_currency: currency,
    },
  };
  pushDataLayerEvent(eventProperties);
}

function pushSearchEvent(
  eventProperties: SearchEventCommonProps | SearchClickEventProps
) {
  pushDataLayerEvent({
    event: 'userEvent',
    event_name: 'internal_search',
    properties: eventProperties,
  });
}

/**
 * Exported as a debounced function, since Coveo analyticsClientMiddleware will get triggered twice for every search event and we don't want to track duplicated events.
 * https://connect.coveo.com/s/article/18262
 */
const debouncedSearchEvent = debounce(pushSearchEvent, 1000);

function pushFilterEvent(eventProperties: FilterEventProps) {
  pushDataLayerEvent({
    event: 'userEvent',
    event_name: 'filter_action',
    properties: eventProperties,
  });
}

/**
 * Exported as a debounced function, since Coveo analyticsClientMiddleware will get triggered twice for every search event and we don't want to track duplicated events.
 * https://connect.coveo.com/s/article/18262
 */
const debouncedFilterEvent = debounce(pushFilterEvent, 1000);

function pushProductClickEvent(
  productEventProperties: ProductEventProperties,
  currency: string
) {
  const productClickEvent: ProductClickEvent = {
    event: 'ecomm',
    event_name: 'product_click',
    properties: {
      ecommerce_currency: currency,
      ecommerce_store: 'assetstore',
      products: [productEventProperties],
    },
  };
  pushDataLayerEvent(productClickEvent);
}

function pushAccordionClickEvent(
  accordionEventProperties: AccordionEventProperties
) {
  const accordionClickEvent: AccordionEvent = {
    event: 'userEvent',
    event_name: 'FAQ_action',
    properties: accordionEventProperties,
  };
  pushDataLayerEvent(accordionClickEvent);
}

function pushPromoViewEvent(promoEventProperties: PromoViewEventProperties) {
  const accordionClickEvent: PromoViewEvent = {
    event: 'promos',
    event_name: 'promo_view',
    properties: promoEventProperties,
  };
  pushDataLayerEvent(accordionClickEvent);
}

function pushPromoClickEvent(promoEventProperties: PromoClickEventProperties) {
  const accordionClickEvent: PromoClickEvent = {
    event: 'promos',
    event_name: 'promo_click',
    properties: promoEventProperties,
  };
  pushDataLayerEvent(accordionClickEvent);
}

export const analytics = {
  initialize,
  pushSearchEvent,
  debouncedSearchEvent,
  debouncedFilterEvent,
  pushProductClickEvent,
  pushAccordionClickEvent,
  pushPromoViewEvent,
  pushPromoClickEvent,
};
