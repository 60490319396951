import { DEFAULT_SEARCH_PAGE_SIZE } from '@lib/constants/coveo';
import { getSiteUrl } from '@lib/util/getSiteUrl';
import { formatTitleForID } from 'utils';
import {
  PromoClickEventProperties,
  PromoEventCommonProperties,
  PromoViewEventProperties,
  SearchAction,
  SearchClickEventProps,
  SearchEventCommonProps,
} from './analyticsEventTypes';

function coveoAnalyticsPayloadToDataLayer(
  eventName: SearchAction,
  coveoAnalyticsPayload: any
) {
  const filterValues = formatCoveoFilterValues(
    coveoAnalyticsPayload.facetState
  );

  let eventProperties: SearchEventCommonProps = {
    search_action: eventName,
    search_total_results: coveoAnalyticsPayload.numberOfResults,
    search_query: coveoAnalyticsPayload.queryText,

    search_filter_price: filterValues.ec_price_filter,
    search_filter_rating: filterValues.ec_rating,
    search_filter_unity_version: filterValues.min_unity_version,
    search_filter_sales: filterValues.ec_sale_filters,
    search_filter_categories: filterValues.ec_category,
    search_sort_by: coveoAnalyticsPayload.customData.resultsSortBy, // type value
    search_engine: 'coveo', // type value, 'internal', 'coveo' or any other
  };

  if (eventName === 'search_result_click') {
    // documentUri = "assetstore://product/PRODUCT_ID"
    const productId =
      coveoAnalyticsPayload.documentUri.split('/')[
        coveoAnalyticsPayload.documentUri.split('/').length - 1
      ];

    eventProperties = {
      ...eventProperties,
      search_result_click_href: `${getSiteUrl()}/packages/package/${productId}`,
      search_result_click_position:
        coveoAnalyticsPayload.documentPosition % DEFAULT_SEARCH_PAGE_SIZE === 0
          ? DEFAULT_SEARCH_PAGE_SIZE
          : coveoAnalyticsPayload.documentPosition % DEFAULT_SEARCH_PAGE_SIZE,
      search_result_click_page_number: Math.ceil(
        coveoAnalyticsPayload.documentPosition / DEFAULT_SEARCH_PAGE_SIZE
      ),
    } as SearchClickEventProps;
  }

  return eventProperties;
}

function formatCoveoFilterValues(facetState: any[]) {
  const filterValues: any = {};
  facetState.forEach((facet) => {
    const { field } = facet;
    switch (field) {
      case 'ec_price_filter':
        filterValues[field] = filterValues[field] || [];
        filterValues[field].push(
          facet.value === '0..0' ? 'Free' : facet.value?.replace('..', '-')
        );
        break;
      case 'ec_rating':
        filterValues[field] = facet.start;
        break;
      case 'min_unity_version':
        filterValues[field] = facet.end
          ?.toString()
          .slice(0, facet.end.toString().length - 2);
        break;
      case 'ec_sale_filters':
        filterValues[field] = filterValues[field] || [];
        filterValues[field].push(facet.value);
        break;
      case 'ec_category':
        filterValues[field] = [facet.value.replace(';', '/')];
        break;
      default:
        filterValues[field] = facet.value;
    }
  });

  return filterValues;
}

const getFacetValue = (facetField: string, customData: any) => {
  switch (facetField) {
    case 'ec_category':
      // facetSelect or facetDeselect actionCause has the value in facetValue, separated by ;
      // breadcrumbFacet actionCause has the value in categoryFacetPath as an array of strings
      return (
        customData.facetValue?.replaceAll(';', '/') ??
        customData.categoryFacetPath?.join('/')
      );
    case 'ec_price_filter':
    case 'min_unity_version':
    case 'ec_rating':
      // These are all the facets that use ranges
      // facetSelect or facetDeselect actionCause has the value in facetValue
      // breadcrumbFacet actionCause has the value in facetRangeStart and facetRangeEnd
      return (
        customData.facetValue ??
        `${customData.facetRangeStart}...${customData.facetRangeEnd}`
      );
    default:
      return customData.facetValue;
  }
};

const coveoAnalyticsPayloadToFilterActionProps = (
  coveoAnalyticsPayload: any
) => {
  if (coveoAnalyticsPayload.actionCause === 'breadcrumbResetAll') {
    return {
      filter_action: 'deselect',
      filter_action_category: 'all',
      filter_action_label: 'all',
    };
  }

  const { facetState, customData } = coveoAnalyticsPayload;
  let { facetField, facetValue } = customData;
  facetField = facetField ?? customData.categoryFacetField;
  // breadcrumbFacet and facetClearAll actionCause are always deselecting
  // otherwise, check if the value is present in the current facetState
  const isSelected =
    coveoAnalyticsPayload.actionCause === 'breadcrumbFacet' ||
    coveoAnalyticsPayload.actionCause === 'facetClearAll'
      ? false
      : facetState?.some(
          (facet) => facet.field === facetField && facet.value === facetValue
        );
  return {
    filter_action: isSelected ? 'select' : 'deselect',
    filter_action_category: facetField,
    filter_action_label: getFacetValue(facetField, customData) ?? '',
  };
};

const toSharedPromoEventProperties = ({
  title,
  typename,
  position,
  creativeTitle,
}: {
  title: string;
  typename: string;
  position: number;
  creativeTitle?: string;
}): PromoEventCommonProperties => {
  return {
    promotion_id: `${formatTitleForID(title)}-${typename}`,
    promotion_name: title || `unnamed-${typename}`,
    promotion_position: `slot${position}`,
    promotion_creative: creativeTitle,
  };
};

export const toPromoViewTrackingEventProperties = ({
  title,
  typename,
  position,
  ctas,
  creativeTitle,
}: {
  title: string;
  typename: string;
  position: number;
  ctas?: string[];
  creativeTitle?: string;
}): PromoViewEventProperties => {
  return {
    ...toSharedPromoEventProperties({
      title,
      typename,
      position,
      creativeTitle,
    }),
    promotion_CTAs_displayed: ctas,
  };
};

export const toPromoClickTrackingEventProperties = ({
  title,
  typename,
  position,
  ctaLabel,
  creativeTitle,
}: {
  title: string;
  typename: string;
  position: number;
  ctaLabel: string;
  creativeTitle?: string;
}): PromoClickEventProperties => {
  return {
    ...toSharedPromoEventProperties({
      title,
      typename,
      position,
      creativeTitle,
    }),
    promotion_CTA_clicked_text: ctaLabel,
  };
};

export const formatAnalytics = {
  coveoAnalyticsPayloadToDataLayer,
  coveoAnalyticsPayloadToFilterActionProps,
  toPromoViewTrackingEventProperties,
  toPromoClickTrackingEventProperties,
};
