import { SearchAction } from '@lib/analytics/analyticsEventTypes';
import { formatAnalytics } from '@lib/analytics/formatAnalyticsProperties';
import { analytics } from '@lib/analytics/page-events';

const isFilterEvent = (actionCause: string) =>
  actionCause === 'facetSelect' ||
  actionCause === 'facetDeselect' ||
  actionCause === 'breadcrumbFacet' ||
  actionCause === 'breadcrumbResetAll' ||
  actionCause === 'facetClearAll';

/**
 * Middleware that hooks into coveo's analytics payload before it gets sent to Coveo.
 * We use it to send the payload to our own analytics system (through the dataLayer).
 *
 * https://docs.coveo.com/en/headless/latest/usage/headless-usage-analytics/
 */
export const analyticsClientMiddleware = (
  coveoEventName: string,
  payload: any
) => {
  // send filter_action event instead of internal_search event
  if (isFilterEvent(payload.actionCause)) {
    // we have to debounce this since Coveo sends 2 analytics events for every search
    // https://connect.coveo.com/s/article/18262
    analytics.debouncedFilterEvent(
      formatAnalytics.coveoAnalyticsPayloadToFilterActionProps(payload)
    );

    return payload;
  }

  if (payload.responseTime === 0 || payload.queryText === '') {
    // ignores the first event that goes through as it has no data yet,
    // need to wait for the second call to go through so that we can see the number of results and other data
    // also: doesn't send any tracking events for empty searches
    return payload;
  }

  let eventName: SearchAction = 'search_initiated';
  if (coveoEventName === 'click') {
    eventName = 'search_result_click';

    analytics.pushSearchEvent(
      formatAnalytics.coveoAnalyticsPayloadToDataLayer(eventName, payload)
    );
  } else {
    // we have to debounce this since Coveo sends 2 analytics events for every search
    // https://connect.coveo.com/s/article/18262
    analytics.debouncedSearchEvent(
      formatAnalytics.coveoAnalyticsPayloadToDataLayer(eventName, payload)
    );
  }

  return payload;
};
